.getTouch {
  .formGroup {
    width: calc(50% - 10px);
    position: relative;
  }

  .stepIndicator {
    min-width: 120px;
    background-color: #0fe3e3;
    flex: none;
    justify-content: flex-start;
    align-self: center;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 10px;
    display: flex;
    position: relative;
    color: #000;
    transition: opacity 0.3s ease;
    border-radius: 10px 0 0 10px;

    h1 {
      color: inherit;
      border: 2px solid #000;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .contactBox {
    border: 2px solid #04b0b0;
    border-radius: 8px;
    padding: 30px;
    width: 100%;
    max-width: 100%;
  }

  .contactRow {
    display: flex;
    align-items: center;
    padding-top: 120px;
  }

  &.serviceBg {
    &::before {
      background-image: url(../../../assets/images/webDevBg.jpg);
      background-repeat: repeat;
      background-size: contain;
    }
  }
}

.RangeSlider {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 48px;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
}

.activeBar {
  transition: height 0.6s ease-in-out; // Smooth transition effect
}

input[type="submit"].disabled,
input[type="submit"]:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.dy {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  z-index: 1;
  border: 2px solid red;
  width: fit-content;
  height: fit-content;
  line-height: 1;
  padding: 20px;

  &::after {
    content: "";
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    position: absolute;
    top: 0;
    left: -100%;
  }
}

@media screen and (max-width: 1199.98px) {
  .getTouch .contactRow {
    padding-top: 90px;
  }
}

@media screen and (max-width: 991.98px) {
  .getTouch .stepIndicator {
    min-width: fit-content;
    padding: 0px;
    background-color: #fff;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    position: absolute;
    left: 25px;
    transform: translateY(-50%);
  }

  .getTouch .contactRow {
    padding-top: 0;
    margin-top: 70px;
    flex-direction: column;
  }

  .getTouch .stepIndicator h1 {
    border-color: #04b0b0;
  }
}

@media screen and (max-width: 575px) {
  .getTouch .formGroup {
    width: 100%;
  }

  .getTouch .contactBox {
    padding: 12px;
    padding-top: 40px;
  }
}