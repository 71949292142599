@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
}

.rubikFont {
  font-family: 'Rubik', sans-serif;
}
.nunitoFont {
  font-family: 'Nunito', sans-serif;
}
.interFont {
  font-family: 'Inter', sans-serif;
}


.white-text {
  color: white;
}

.black-text {
  color: black;
}
html.bgFreeze{
  overflow: hidden;
}