.workProcess {
    background-color: #006363;
    color: #fff;
    .titleMain {
        position: relative;
        z-index: 1;
        margin-bottom: 50px;
        margin-top: 30px;

        &::before {
            content: "";
            width: 77px;
            height: 77px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            left: -35px;
            top: 50%;
            transform: translateY(-50%);
            background-color: #04B0B0;
            z-index: -1;
        }
    }
    .workRow{
        margin-bottom: 100px;
        &:nth-of-type(even){
            flex-direction: row-reverse;
        }
    }
}

@media (max-width: 1690px) { 
    .workProcess .titleMain::before {
        width: 65px;
        height: 65px;
    }
    .workProcess .titleMain {
        margin-bottom: 30px;
        margin-top: 20px;
    }
    .workProcess .workRow {
        margin-bottom: 60px;
    }
 }

 @media (max-width: 1500px) { 
    .workProcess .titleMain::before {
        width: 50px;
        height: 50px;
        left: -25px;
    }
    .workProcess .titleMain {
        margin-bottom: 25px;
        margin-top: 15px;
    }

 }
 @media (max-width: 1370px) { 
    .workProcess .titleMain::before {
        width: 40px;
        height: 40px;
    }
    .workProcess .titleMain {
        margin-bottom: 15px;
    }
    .workProcess .workRow {
        margin-bottom: 40px;
    }

 }
 @media (max-width: 1199.98px) { 
    .workProcess .titleMain::before {
        width: 35px;
        height: 35px;
        left: -15px;
    }
 }
 @media (max-width: 991.98px) { 
    .workProcess .workRow:nth-of-type(even) {
        flex-direction: column-reverse;
    }
 }
 @media (max-width: 575px) { 
    .workProcess .titleMain {
        margin-bottom: 10px;
    }
 }