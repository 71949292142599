.scaler_item_wrapper {
    z-index: 3;
    width: 15px;
    height: 15px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    display: flex;
    position: relative;

    &.active .circle {
        background-color: #0FE3E3;
        transform: translate3d(0px, 0px, 0px) scale3d(1.4, 1.4, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
        transform-style: preserve-3d;
    }

    &.active.current .pulse {
        animation: pulse 2s linear infinite;
        border: 2px solid #2468ff;
    }

    @keyframes pulse {
        0% {
            box-shadow: 0 0 0 0px rgba(255, 255, 255, 0.2);
        }

        100% {
            box-shadow: 0 0 0 20px rgba(255, 255, 255, 0);
        }
    }

    .circle_link {
        z-index: 5;
        width: 100%;
        height: 100%;
        max-width: 100%;
        position: absolute;
        top: 0%;
        bottom: 0%;
        left: 0%;
        right: 0%;
        background-color: rgba(0, 0, 0, 0);
    }

    .pulse {
        width: 10px;
        height: 10px;
        border-radius: 100%;
        position: absolute;
        top: auto;
        bottom: auto;
        left: auto;
        right: auto;

        &.pulse2 {
            animation-delay: 1s;
        }
    }

    .circle {
        z-index: 5;
        max-width: 100%;
        position: absolute;
        top: 0%;
        bottom: 0%;
        left: 0%;
        right: 0%;
        width: 15px;
        height: 15px;
        background-color: #c1c1c1;
        border: 2px solid #fff;
        border-radius: 100%;
        justify-content: center;
        align-items: center;
        display: flex;
        position: relative;
    }

    .circle-text {
        padding-top: 15px;
        font-size: 16px;
        line-height: 1.2em;
        position: absolute;
        top: 15px;
        text-align: center;
        color: #FFF;
    }
}

.price_arrow_wrapper {
    width: 100%;
    max-width: 110px;
    bottom: 8px;
    left: -200px;
    position: absolute;

    .price_arrow {
        width: 100%;
        max-width: 74px;
        top: auto;
        bottom: -14px;
        left: auto;
        right: -67px;
        position: absolute;
        display: block;
    }

    .price_arrow_text {
        font-size: var(--fs22);
        color: #0FE3E3;
        font-weight: 600;
        line-height: 112%;
        margin-bottom: 30px;
        text-align: center;
    }
}

.tabs_scaler {
    grid-column-gap: 10px;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    display: flex;
    position: relative;
    max-width: 1080px;
    width: 100%;
    margin: 0 auto;
    margin-top: 70px;
    z-index: 0;

    .line {
        z-index: 1;
        width: 100%;
        height: 3px;
        background-color: #c1c1c1;
        position: absolute;

        &.line_ {
            background-color: #0FE3E3;
        }
    }
}
.pricingBtn{
    &:hover{
        color: #fff;
        svg path{
            fill: #fff;
        }
    }
    &::before{
        background-color: #0FE3E3;
    }
}


@media (max-width: 1690px) {
    .tabs_scaler {
        max-width: 900px;
    }
    .price_arrow_wrapper .price_arrow {
        max-width: 65px;
    }
    .scaler_item_wrapper .circle-text {
       font-size: 15px;
    }
}

@media (max-width: 1560px) {
    .price_arrow_wrapper .price_arrow_text {
        font-size: 19px;
        margin-bottom: 20px;
    }
}

@media (max-width: 1370px) {
    .tabs_scaler {
        margin-top: 50px;
        max-width: 850px;
    }
    .price_arrow_wrapper .price_arrow_text {
        font-size: 17px;
        margin-bottom: 15px;
    }
}
@media (max-width: 1199.98px) {
    .price_arrow_wrapper{
        bottom: 55px;
        left: 61px;
    }
    .price_arrow_wrapper .price_arrow{
        max-width: 50px;
    bottom: -13px;
    left: -55px;
    right: auto;
    transform: rotate(120deg);
    }
}
@media (max-width: 991.98px) {
    .price_arrow_wrapper .price_arrow_text {
        font-size: 15px;
        margin-bottom: 10px;
    }
    .price_arrow_wrapper {
        max-width: 80px;
        left: 40px;
    }
    .price_arrow_wrapper .price_arrow {
        max-width: 40px;
        left: -40px;
    }
    .tabs_scaler {
        max-width: 660px;
        margin-top: 90px;
    }
    .scaler_item_wrapper .circle-text {
        font-size: 12px;
    }
}
@media (max-width: 575px) {
    .scaler_item_wrapper .circle-text {
        font-size: 8px;
        padding-top: 10px;
    }
    .mobile_none{
        display: none;
    }
}