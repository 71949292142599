#navBarId {
    z-index: 999;
    height: 98.5px;
}

@keyframes navbar {
    0% {
        transform: translateY(-100%);
    }

    100% {
        transform: unset;
    }
}


.slideIn {
    transition: all 0.3s;

    &::before {
        content: "";
        background-color: rgba(0, 0, 0, 0.5);
        position: absolute;
        width: 200vw;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
        transition: max-height 0.2s ease-in-out;
    }
}

.activeNav {
    color: #0FE3E3 !important;
    position: relative;
    padding-bottom: 10px;

    &::before {
        content: '';
        border-radius: 20px;
        background: #0FE3E3;
        width: 27px;
        height: 3px;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
    }
}

.hasDrop {
    opacity: 0;
    visibility: hidden;
}

.dropWrap {
    .hasDrop {
        padding-top: 12px;
        padding-bottom: 12px;
        position: absolute;
        top: 120%;
        left: 0;
        min-width: 220px;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        opacity: 0;
        visibility: hidden;
        box-shadow: 0px 0px 65px 0px rgba(0, 0, 0, 0.1);
        transform-origin: top center;
        transform: scaleY(0) translateZ(100px);
        transition: opacity 200ms ease, visibility 200ms ease, transform 400ms ease, -webkit-transform 400ms ease;
        z-index: 99;
        min-width: 240px;

        a {
            display: block;
            line-height: 1.2;
            white-space: nowrap;
            font-size: var(--fs16);
            padding: 12px 20px;
            width: 100%;

            &:last-child {
                border: 0;
            }
        }

    }

    .dropIcon {
        transition: all 0.5s;
    }


    &.__open {
        .hasDrop {
            opacity: 1;
            visibility: visible;
            transform: scaleY(1) translateZ(0px);
        }

        .dropIcon {
            transform: rotate(180deg);
        }

    }


}


.navBtn {
    font-weight: 600;

    &:hover {
        color: #fff;

        svg path {
            fill: #fff;
        }
    }

    &::before {
        background-color: #04B0B0;
    }
}

@media (max-width: 1680px) {
    #navBarId {
        height: 75px;
    }
}

@media (max-width: 1370px) {
    .dropdown-entered {
        a {
            font-size: 13px;
        }
    }

    #navBarId {
        height: 65px;
    }
}

@media (max-width: 991px) {
    .dropWrap .hasDrop{
        min-width: 100%;
        transform: unset;
        position: unset;
        background-color: transparent;
        padding: 0;
    }
    .dropWrap .hasDrop a {
    padding: 4px 6px;
}
    .dropWrap.__open .hasDrop{
        opacity: 0;
        visibility: hidden;
        height: 0;
        padding: 0;
    }
    .dropdown-entered a {
        display: block;
    }

    .dropdown-entering {
        visibility: hidden;
        opacity: 0;
        height: 0;
    }

    .dropWrap .hasDrop.dropdown-entered {
        height: 100%;
        padding: 10px;
        animation: display 0.3s;
        opacity: 1;
        visibility: visible;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .activeNav {
        padding-bottom: 7px;
    }

    .activeNav::before {
        left: 0;
        transform: unset;
        width: 20px;
    }

    @keyframes opacity {
        0% {
            display: none;
        }

        100% {
            display: flex;
        }
    }
}

@media (max-width: 575px) {
    .dropdown-entered a {
        border-bottom: none;
        color: #fff;
    }

    #navBarId {
        height: 55px;
    }
}