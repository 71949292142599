.portfolioBox {
    width: calc(33.33% - 35px);
    position: relative;
    cursor: pointer;
    height: 395px;

    &:hover {
        &::before {
            display: block;
            z-index: 3;
        }

        .previewIcon {
            opacity: 1;
            z-index: 3;
            border-bottom: 1px solid #fff;
        }

        .zoomIcon {
            opacity: 1;
            z-index: 3;
        }

        .portfolioImg {
            object-position: bottom center;
        }
    }

    &::before {
        content: '';
        background-color: rgba(0, 0, 0, 0.3);
        width: 100%;
        height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 20px;
        display: none;
        z-index: 3;
        display: none;
    }

    .previewIcon {
        position: absolute;
        opacity: 0;
        transition: all 0.3s ease;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: fit-content;
        width: fit-content;
    }

    .zoomIcon {
        position: absolute;
        top: 16px;
        right: 22px;
        opacity: 0;
        transition: all 0.5s ease;
    }

    .portfolioImg {
        position: relative;
        z-index: 2;
        width: 100%;
        object-fit: cover;
        height: 100%;
        border-radius: 20px;
        object-position: top center;
        transition: all 2s ease-in-out;
    }

    .portfolioShadow {
        position: absolute;
        top: 20px;
        left: -20px;
        opacity: 0.5;
        filter: blur(2px);
        z-index: 1;
        width: 100%;
        object-fit: cover;
        height: 100%;
        border-radius: 20px;
        object-position: top center;
        transition: all 2s ease-in-out;
    }
}

// light box start 
.lightbox-backdrop {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 9999999999;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &::before {
        content: '';
        background-color: rgba(0, 0, 0, 0.7);
        width: 100%;
        height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .lightbox-content {
        position: relative;
        max-width: 1290px;
        width: 100%;
        height: auto;
        margin-left: auto;
        margin-right: auto;
    }

    .lightBoxImg {
        height: 75vh;
        overflow-y: auto;
        margin-left: auto;
        margin-right: auto;
        width: 100%;

        &::-webkit-scrollbar {
            width: 6px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: #f1f1f1;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #888;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: #555;
        }
    }

    .lightbox-close {
        position: absolute;
        right: 0px;
        top: 16px;
        width: 25px;
        height: 25px;
        cursor: pointer;
    }

    .lightbox-prev {
        position: absolute;
        left: 20px;
        top: 50%;
        transform: translateY(-50%);
        width: 35px;
        height: 60px;
        cursor: pointer;

        svg {
            width: 100% !important;
            height: 100% !important;
        }
    }

    .lightbox-next {
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        width: 35px;
        height: 60px;
        cursor: pointer;

        svg {
            width: 100% !important;
            height: 100% !important;
        }
    }
}

@media (max-width: 1690px) {
    .portfolioBox {
        height: 376px;
    }
}

@media (max-width: 1560px) {
    .portfolioBox {
        height: 300px;
    }

    .lightbox-backdrop .lightbox-prev {
        width: 24px;
        height: 48px;
    }

    .lightbox-backdrop .lightbox-next {
        width: 24px;
        height: 48px;
    }


}

@media (max-width: 1500px) {
    .portfolioBox:hover .zoomIcon {
        max-width: 55px;
        width: 100%;
    }

    .portfolioBox {
        width: calc(33.33% - 30px);
    }

    .lightbox-backdrop .lightbox-content {
        max-width: 1000px;
    }
}

@media (max-width: 1370px) {
    .lightbox-backdrop .lightbox-content {
        max-width: 900px;
    }

    .portfolioBox {
        width: calc(33.33% - 25px);
        height: 240px;
    }

    .portfolioBox .portfolioShadow {
        top: 15px;
        left: -15px;
    }

    .portfolioBox:hover .zoomIcon {
        max-width: 45px;
        width: 100%;
        top: 12px !important;
        right: 18px !important;
    }

    .lightbox-backdrop .lightBoxImg {
        height: 80vh;
    }


}

@media (max-width: 1198.99px) {
    .lightbox-backdrop .lightBoxImg {
        height: 70vh;
    }
}

@media (max-width: 991px) {
    .portfolioBox {
        width: calc(33.33% - 20px);
        height: 200px;
    }

    .lightbox-backdrop .lightbox-content {
        max-width: calc(100% - 50px);
    }
}

@media (max-width: 575px) {
    .portfolioBox {
        width: 90%;
        margin: auto;
        height: 300px;
    }

    .lightbox-backdrop .lightbox-content {
        max-width: calc(100% - 25px);
    }

    .lightbox-backdrop .lightbox-close {
        right: 10px;
        top: 10px;
        background-color: #fff;
        fill: #000;
        border-radius: 50%;
        padding: 4px;
        box-shadow: 0 1px 6px #000;
    }

    .lightbox-backdrop .lightbox-prev {
        left: 10px;
        width: 20px;
        height: 20px;
    }

    .lightbox-backdrop .lightbox-next {
        right: 10px;
        width: 20px;
        height: 20px;
    }

}