.customBtn{
    position: relative;
    overflow: hidden;
    transition: background-position 0.6s ease;
    z-index: 1;
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        transition: left 0.6s ease;
        z-index: -1;
        border-radius: 0;
    }
    &:hover {
        background-position: 100% 0;
        &::before {
            left: 0;
        }
    }
}