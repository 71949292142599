.chooseBox {
    box-shadow: 0 10px 26px 1px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
    transition: all 0.5s ease;
    position: relative;
    z-index: 1;

    &::before {
        content: "";
        background-color: #0FE3E3;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        visibility: hidden;
        transform-origin: top center;
        transform: scaleY(0) translateZ(100px);
        transition: opacity 200ms ease, visibility 200ms ease, transform 400ms ease, -webkit-transform 400ms ease;
        border-radius: inherit;
        z-index: -1;
    }

    &:hover {
        &::before {
            opacity: 1;
            visibility: visible;
            transform: scaleY(1) translateZ(0px);
        }
        .iconBox{
background-color: #000;
color: #fff;
        }
    }
}