.serviceBg {
    position: relative;
    z-index: 1;
    min-height: calc(100vh - 150.5px);

    &::before {
        content: "";
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        min-height: calc(100% + 98.5px);
        top: -98.5px;
        position: absolute;
        background-image: var(--bg-image);
        z-index: -1;
    }
    &::after {
        content: "";
        background-color: rgba($color: #fff, $alpha: 0.6);
        width: 100%;
        min-height: calc(100% + 98.5px);
        top: -98.5px;
        position: absolute;
        z-index: -1;
    }
}

@media (max-width: 1690px) {
    .serviceBg {
        min-height: calc(100vh - 113.42px);

        &::before {
            min-height: calc(100% + 75.42px);
            top: -75.8px;
        }
        &::after {
            min-height: calc(100% + 75.42px);
            top: -75.8px;
        }
    }
}

@media (max-width: 1500px) {
    // .serviceBg {
    //     min-height: calc(100vh - 97.19px);

    //     &::before {
    //         min-height: calc(100% + 59.19px);
    //         top: -59.19px;
    //     }
    //     &::after {
    //         min-height: calc(100% + 59.19px);
    //         top: -59.19px;
    //     }
    // }
}

@media (max-width: 1370px) {
    .serviceBg {
        min-height: calc(100vh - 99px);

        &::before {
            min-height: calc(100% + 65px);
            top: -65px;
        }
        &::after {
            min-height: calc(100% + 65px);
            top: -65px;
        }
    }
}

@media (max-width: 1198.99px) {
    .serviceBg {
        min-height: 70vh;
    }
}

@media (max-width: 991px) {
}

@media (max-width: 575px) {
    .serviceBg {
        min-height: auto;
        padding: 50px 0;

        &::before {
            min-height: calc(100% + 55px);
            top: -55px;
        }
        &::after {
            min-height: calc(100% + 55px);
            top: -55px;
        }
    }
}