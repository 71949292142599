:root {
    --fs50: 50px;
    --fs45: 45px;
    --fs40: 40px;
    --fs36: 36px;
    --fs32: 32px;
    --fs30: 30px;
    --fs28: 28px;
    --fs24: 24px;
    --fs22: 22px;
    --fs20: 20px;
    --fs18: 18px;
    --fs16: 16px;
    --p100: 100px;
    --p80: 80px;
}
.py100{
    padding-top: var(--p100);
    padding-bottom: var(--p100);
}
.pt100{
    padding-top: var(--p100);
}
.pb100{
    padding-bottom: var(--p100);
}
.pb80{
    padding-bottom: var(--p80);
}
.pt80{
    padding-top: var(--p80);
}
.py80{
    padding-top: var(--p80);
    padding-bottom: var(--p80);
}
.fs50{
    font-size: var(--fs50);
}
.fs45{
    font-size: var(--fs45);
}
.fs40{
    font-size: var(--fs40);
}
.fs36{
    font-size: var(--fs36);
}
.fs32{
    font-size: var(--fs32);
}
.fs30{
    font-size: var(--fs30);
}
.fs28{
    font-size: var(--fs28);
}
.fs24{
    font-size: var(--fs24);
}
.fs22{
    font-size: var(--fs22);
}
.fs20{
    font-size: var(--fs20);
}
.fs18{
    font-size: var(--fs18);
}
.fs16{
    font-size: var(--fs16);
}
h1,h2,h3{
    font-weight: 700 !important;
}

@media screen and (max-width: 1680px) {
    :root {
        --fs45: 38px;
        --fs40: 34px;
        --fs36: 32px;
        --fs32: 30px;
        --fs30: 24px;
        --fs24: 22px;
        --fs22: 20px;
        --fs20: 18px;
        --fs18: 16px;
        --p80: 50px;
        --p100: 80px;
    }
}
@media screen and (max-width: 1570px) {
    :root {
        --fs45: 34px;
        --fs40: 30px;
        --fs36: 28px;
        --fs32: 26px;
        --fs30: 22px;
        --fs28: 22px;
        --fs24: 20px;
        --fs22: 18px;
        --fs20: 16px;
    }
}
@media screen and (max-width: 1500px) {
    :root {
        --fs24: 18px;
        --fs50: 42px;
    }
}
@media screen and (max-width: 1370px) {
    :root {
        --fs50: 36px;
        --fs45: 26px;
        --fs40: 24px;
        --fs36: 22px;
        --fs32: 21px;
        --fs30: 20px;
        --fs28: 18px;
        --fs24: 16px;
        --fs22: 16px;
        --fs20: 15px;
        --fs18: 14px;
        --p80: 40px;
        --p100: 70px;
    }
}
@media screen and (max-width: 1198.99px) {
    :root {
        --fs40: 21px;
        --fs30: 19px;
        --fs28: 17px;
    }
}
@media screen and (max-width: 991.99px) {
    :root {
        --fs50: 30px;
        --fs40: 19px;
    }
}
@media screen and (max-width: 575.99px) {
    :root {
        --fs45: 20px;
        --fs40: 18px;
        --fs36: 18px;
        --fs30: 16px;
        --fs24: 15px;
        --fs22: 15px;
        --fs20: 14px;
        --fs18: 13px;
        --p80: 30px;
        --p100: 50px;
    }
}