.footerWrap {
    position: relative;
    z-index: 1;
    color: #fff;

    &::before {
        content: '';
        background-image: url(../../assets/images/Footer_background.png);
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: -1;
    }

    .upperLayer {
        display: flex;
        justify-content: space-between;
        a {
            text-decoration: underline;
        }
    }

    .mainLayer {
        display: flex;
        justify-content: space-between;
        gap: 40px;
        position: relative;
        &::before{
            content: '';
            background-color: #04B0B0;
            height: 1px;
            width: 100vw;
            left: 50%;
            transform: translateX(-50%);
            bottom: 0;
            position: absolute;
        }

        .footerBox {
            max-width: 40%;
            width: fit-content;

            svg {
                min-width: 100%;
                min-height: 100%;
                width: 100%;
                height: 100%;
            }
            a {
                &+a {
                    margin-top: 20px;
                }
            }
        }

        .formGroup {
            position: relative;
            margin-top: 30px;

            input {
                background: unset;
                border-radius: 10px;
                border: 1px solid #04B0B0;
                width: 100%;
                height: 60px;
                color: #FFF;
                font-size: 14px;
                padding: 15px 55px 15px 30px;
            }

            a {
                position: absolute;
                right: 8px;
                top: 50%;
                transform: translateY(-50%);
                z-index: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 45px;
                background: #0FE3E3;
                box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.50);
                width: 45px;
                height: 45px;
                border-radius: 50%;
            }
        }
    }
    .lastLayer{
        padding: 40px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        svg{
            width: 16px;
        }
    }
}

@media (max-width: 1690px) {
    .footerWrap .mainLayer .footerBox a + a {
        margin-top: 12px;
    }
    .footerWrap .mainLayer .formGroup {
        margin-top: 24px;
    }
    .footerWrap .mainLayer .formGroup input {
        padding: 12px 50px 12px 20px;
    }
    .footerWrap .mainLayer .formGroup a {
        width: 40px;
        height: 40px;
    }
    .footerWrap .lastLayer {
        padding: 25px 0;
    }
    
}
@media (max-width: 1500px) {
    .footerWrap .mainLayer .footerBox a + a {
        margin-top: 9px;
    }
}
@media (max-width: 1370px) {
    .footerWrap .mainLayer .formGroup a {
        width: 35px;
        height: 35px;
        padding: 10px;
    }
    .footerWrap .mainLayer .formGroup input {
        height: 50px;
    }
    .footerWrap .lastLayer {
        padding: 16px 0;
    }
}

@media (max-width: 1199.98px) {
}
@media (max-width: 991.98px) {
    .footerWrap .upperLayer {
        flex-direction: column;
        gap: 20px;
    }
    .footerWrap .mainLayer {
        flex-direction: column;
        gap: 20px;
    }
    .footerWrap .mainLayer .footerBox {
        max-width: 100%;
        a{
            &+ a{
                margin-top: 7px !important;
            }
        }
    }
    
}
@media (max-width: 575px) {
    .footerWrap .lastLayer {
        padding: 10px 0;
        flex-direction: column;
        justify-content: center;
        gap: 10px;
    }
    
}