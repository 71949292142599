@import './fonts';
@import './typography';

body{
    font-size: var(--fs16);
    line-height: 120%;
    font-weight: 400;
    line-height: 1.2;
}
body,
html {
    overflow-x: hidden;
}

* {
    box-sizing: border-box;
}

img {
    max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
}

a:focus-visible {
    outline: none;
}

input:focus-visible {
    outline: none;
}
textarea:focus-visible {
    outline: none;
}

ul {
    list-style: none;
}

.accordion-button:not(.collapsed) {
    box-shadow: none;
}

.accordion-item:first-of-type .accordion-button {
    border-radius: 0;
    box-shadow: none;
}

.accordion-button {
    background-color: unset !important;
}

.accordion-button:focus-visible {
    outline: none;
}

.accordion-button:focus {
    box-shadow: none;
}

.accordion-button:not(.collapsed) {
    background-color: unset;
}

.accordion-item {
    background-color: unset;
    border: 0;
    border-radius: 0;
}

.nav.nav-tabs {
    border: 0;
}

.nav.nav-tabs .nav-link {
    border: 0;
    color: inherit;
    padding: 0;
}

.nav.nav-tabs .nav-link.active {
    outline: none;
    border: 0;
}

.form-control:focus {
    outline: none;
    box-shadow: none;
}

.flex_align {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex_bt {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.container {
    width: 88%;
    margin-left: auto;margin-right: auto;
    padding-left: 15px;padding-right: 15px;
    @media screen and (max-width: 1198.99px) {
        width: 100%;
        padding-left: 10px;padding-right: 10px;
    }
    @media screen and (max-width: 575.99px) {
        padding-left: 8px;padding-right: 8px;
    }
}
