.buisnessBx{
    transition: all 0.3s;
    &:hover{
        box-shadow: rgba(0, 0, 0, 0.07) 0px 4px 55px;
        background: #006363;
        color: #fff;
        *{
            color: inherit;
        }
    }
}
.counterBox{
    width: calc(25% - 10px);
    @media screen and (max-width: 991px) {
        width: calc(50% - 10px);
    }
    @media screen and (max-width: 575px) {
        width: 100%;
    }
}