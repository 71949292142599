.homeBg {
    position: relative;
    z-index: 1;
    height: calc(100vh - 150.5px);

}

.aboutBtn{
    &::before{
        background-color: #04B0B0;
    }
}
.startProjectBtn{
    &::before{
        background-color: #000;
    }
}
.videoSec {
    width: 100% !important;
    position: absolute;
    top: -98.5px;
    left: 50%;
    transform: translate(-50%, 0%);
    height: calc(100% + 98.5px);
    overflow: hidden;
    z-index: 2;

    &::before {
        content: "";
        background-color: rgba($color: #000000, $alpha: 0.4);
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100vw;
        height: 100%;
        transform: translate(-50%, -50%);
        z-index: 1;
    }
    &::after{
        content: "";
        background-image: url(../../../assets/images/heroThumnail.png);
        background-size: cover;
        background-repeat: no-repeat;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: -1;
    }

    video {
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        object-fit: cover;
    }
}

@media (max-width: 1680px) {
    .videoSec {
        top: -75px;
        height: calc(100% + 75px);
    }

    .homeBg {
        height: calc(100vh - 113px);
    }
}

@media (max-width: 1500px) {
    // .homeBg {
    //     height: calc(100vh - 97.19px);
    // }

    // .videoSec {
    //     top: -59.19px;
    //     height: calc(100% + 59.19px);
    // }
}

@media (max-width: 1370px) {
    .homeBg {
        height: calc(100vh - 99px);
    }

    .videoSec {
        top: -65px;
        height: calc(100% + 65px);
    }
}

@media (max-width: 991.98px) {

}

@media (max-width: 575px) {

    .homeBg {
        height: calc(100vh - 89px);
    }

    .videoSec {
        top: -55px;
        height: calc(100% + 55px);
    }
}
