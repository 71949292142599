.contactBox {
    max-width: 795px;
    width: 100%;

    form {
        h1 {
            color: #000;
            font-size: var(--fs40);
            font-weight: 600;
            margin-bottom: 10px;
        }

        h6 {
            color: #454545;
            font-size: var(--fs20);
            font-weight: 400;
            margin-bottom: 20px;
        }

        input,
        textarea {
            height: 90px;
            color: #000;
         font-size: var(--fs20);
            font-weight: 500;
            margin-bottom: 16px;

            &::placeholder {
                color: #DDD;
            }
        }

        textarea {
            height: 183px;
            margin-bottom: 40px;
        }

        a {
            background: #DFFFFF;
            height: 87px;
            margin-left: auto;
            margin-right: auto;
            max-width: 360px;
            width: 100%;
            color: #003D3D;
            font-size: var(--fs24);
            font-weight: 600;
        }
    }
}

@media (max-width: 1690px) {
    .contactBox form h1 {
        font-size: 30px;
    }

    .contactBox form input {
        height: 75px;
        font-size: 18px;
    }

    .contactBox form textarea {
        height: 150px;
    }

    .contactBox form a {
        height: 75px;
        max-width: 300px;
        font-size: 20px;
    }
}

@media (max-width: 1500px) {
    .contactBox form h1 {
        font-size: 26px;
    }

    .contactBox form h6 {
        font-size: 18px;
    }

    .contactBox form input {
        height: 70px;
        font-size: 16px;
    }

    .contactBox form a {
        height: 65px;
        max-width: 265px;
        font-size: 18px;
    }

}

@media (max-width: 1370px) {
    .contactBox form h1 {
        font-size: 19px;
        margin-bottom: 7px;
    }

    .contactBox form h6 {
        font-size: 16px;
    }

    .contactBox form input {
        height: 50px;
        font-size: 15px;
    }

    .contactBox form a {
        height: 55px;
        max-width: 210px;
        font-size: 15px;
    }

    .contactBox form textarea {
        height: 120px;
        margin-bottom: 30px;
    }

    .contactBox {
        max-width: 700px;

        form {
            padding: 0 !important;
        }
    }

    .footerWrap .mainLayer li {
        max-width: 275px;
    }

}

@media (max-width: 1199.98px) {
    .contactBox {
        max-width: 630px;
    }
}
@media (max-width: 991.98px) {
    .contactBox {
        max-width: 100%;
    }
}
@media (max-width: 575px) {
    .contactBox form h6 {
        font-size: 14px;
    }
    .contactBox form input {
        height: 45px;
        font-size: 12px;
    }
    .contactBox form a {
        height: 50px;
        max-width: 190px;
        font-size: 14px;
    }
}