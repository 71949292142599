.servicesWrap{
    .serviceBox{
        width: calc(50% - 25px);
        @media screen and (max-width: 1198.99px) {
            width: calc(50% - 15px);
        }
        @media screen and (max-width: 991.99px) {
            width: calc(50% - 10px);
        }
        @media screen and (max-width: 575.99px) {
            width: 100%;
        }
    }
}