.blogCard{
    box-shadow: 0 4px 3px 0 rgba(34,90,182,.12),0 0 1px 0 rgba(41,92,176,.25);
 transition: all 0.3s;
    &:hover{
        box-shadow: 0 10px 10px 0 rgba(34,90,182,.12),0 0 1px 0 rgba(41,92,176,.25);
    }
}
.blogRow{
    grid-template-areas: "a b b b";
    grid-template-columns: repeat(4, 1fr);
}
@media screen and (max-width:1370px) {
    .blogRow {
        grid-template-areas: "a b b b b";
        grid-template-columns: repeat(5, 1fr);
    }
}
@media screen and (max-width:991px) {
    .blogRow{
        grid-template-areas: "b";
        grid-template-columns: repeat(1, 1fr);
    }
}