canvas {
  z-index: 999999 !important;
  display: none !important;
}

.custom-overlay {
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;

  &::before {
    content: "";
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
  }
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.custom-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -100%);
  background-color: #0FE3E3;
  padding: 16px;
  width: 480px;
  border-radius: 8px;
  transition: transform 1s ease;
  z-index: 999;
  display: none !important;

  &:focus-visible {
    outline: unset;
    box-shadow: unset;
  }

  &.ReactModal__Content--after-open {
    transform: translate(-50%, -50%);
  }
}

.custom-overlay::before{
  display: none !important;
}
.modalVideo {
  overflow: hidden;

  video {
    height: 100%;
    overflow: hidden;
    width: 100%;
    object-fit: unset;
  }
}

@media (max-width: 1690px) {
  .modalVideo {
    height: 430px;
  }
}

@media (max-width: 1570px) {}

@media (max-width: 1500px) {}

@media (max-width: 1370px) {
  .modalVideo {
    height: 360px;
  }

  .custom-modal {
    padding: 12px;
    width: 460px;
  }
}

@media (max-width: 575px) {
  .custom-modal {
    max-width: calc(100% - 30px);
  }

  .modalVideo {
    height: 300px;
  }


}