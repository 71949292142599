/* index.scss */
.chatBotWrap {
    .ibTlds {
        width: 400px;
    }

    .GgOGn {
        margin: 5px;
    }

    .jwudSq {
        max-width: 70%;
    }

    .gLLhJX {
        max-width: 70%;
    }

    .fBGGBn {
        padding: 8px 0;
    }

    .commentIcon {
        position: fixed;
        bottom: 30px;
        right: 30px;
        cursor: pointer;
        z-index: 1001;
        background-color: #0FE3E3;
        border-radius: 50%;
        width: 70px;
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            width: 35px;
        }

        .dots {
            visibility: hidden;
            opacity: 0;
            transition: all 0.3s;
            position: absolute;
            display: flex;

            span {
                display: inline-block;
                margin: 0 1.5px;
                border-radius: 50%;
                width: 5px;
                height: 5px;
                background-color: #fff;
                animation: dotsAnimate 0.8s linear infinite;

                &:nth-of-type(1) {
                    animation-delay: 0.1s;
                }

                &:nth-of-type(2) {
                    animation-delay: 0.3s;
                }

                &:nth-of-type(3) {
                    animation-delay: 0.5s;
                }
            }
        }

        &:hover {
            .dots {
                visibility: visible;
                opacity: 1;
            }
        }
    }

    .chatBotContainer {
        background-color: white;
        border-radius: 10px;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
        transform: translateY(100%);
        transition: transform 0.3s ease-in-out;
        position: fixed;
        bottom: 0px;
        right: 30px;
        /* Adjust according to your design */
        z-index: 1000;
    }

    .chatBotContainer.open {
        transform: translateY(-8px);
    }

    .closeIcon {
        cursor: pointer;
        z-index: 1001;
        right: 16px;
        top: 16px;
        position: absolute;

        svg {
            width: 16px;
            height: 16px;
        }
    }
}

@keyframes dotsAnimate {
    0% {
        transform: translateY(0px);
    }

    50% {
        transform: translateY(2px);
    }

    100% {
        transform: translateY(0px);
    }
}

@media screen and (max-width: 575px) {
    .chatBotWrap .chatBotContainer.open {
        right: 0;
    }
    .chatBotWrap .ibTlds {
        width: 100% !important;
    }
}